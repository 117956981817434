@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
  box-sizing: border-box;
}
body {
  margin: 1em;
  padding: 0px;

  background-color: #212121;
}
p {
  margin: 0px;
  line-height: 30px;
}
th {
  font-weight: 500;
  text-align: center;
}
td {
  transition: all 0.5s;

  padding: 0.5em;
}
